













































































import { Component, Vue } from "vue-property-decorator";
import SearchDataContext from "@/dataContexts/SearchDataContext";
import SpinnerComponent from "@/components/SpinnerComponent.vue";
import _ from "lodash";
import AnimeGridComponent from "@/components/global/grid/AnimeGridComponent.vue";
import CompanyDataContext from "@/dataContexts/CompanyDataContext";
import { SelectComponent, SelectListItem, SelectListItemUtils } from "keiryo";
import TagDataContext from "@/dataContexts/TagDataContext";
import { Constants, Anime, SearchSettings, Tag, Company } from "@sokkuri/common";
import TranslationUtils from "@/common/utilities/TranslationUtils";
import { ValidationObserver } from "vee-validate";

@Component({
    components: {
        AnimeGridComponent,
        SelectComponent,
        SpinnerComponent
    }
})
export default class AnimeSearchView extends Vue {
    private loading = false;

    private selectableTypes: SelectListItem[] = [];
    private selectableStates: SelectListItem[] = [];
    private selectableTags: SelectListItem[] = [];
    private selectableStreamingServices: SelectListItem[] = [];
    private searchResults: Anime[] = [];

    private searchSettings: SearchSettings =  { types: [], states: [], includedTagIds: [], excludedTagIds: [], page: 1 } as SearchSettings;

    private lastSearchResultCount = 0;

    created() {
        this.selectableTypes = SelectListItemUtils.getItems(Constants.AnimeTypes.AnimeTypes, TranslationUtils.translate, (x) => x, [ Constants.AnimeTypes.Series ]);
        this.selectableStates = SelectListItemUtils.getItems(Constants.States.States, TranslationUtils.translate, (x) => x, [ Constants.States.Airing, Constants.States.Finished ]);

        const tagDataContext = new TagDataContext();
        tagDataContext.getAvailableTags().then(x => {
            if (x.successfully && x.data) {
                this.selectableTags = SelectListItemUtils.getItems<Tag>(x.data, (y) => TranslationUtils.translate(y.translationKey), (y) => y.id.toString());
            }
        });

        const companyDataContext = new CompanyDataContext();
        companyDataContext.getCompaniesByType(Constants.CompanyTypes.StreamingService).then(x => {
            if (x.successfully && x.data) {
                this.selectableStreamingServices = SelectListItemUtils.getItems<Company>(x.data, (y) => y.name, (y) => y.id.toString());
            }
        });

        document.addEventListener("scroll", this.onScroll);
    }

    beforeDestroy() {
        document.removeEventListener("scroll", this.onScroll);
    }

    // Infinity Scroll
    private onScroll() {
        this.$nextTick(() => {
            // Search only if search results are still possible.
            if (this.searchSettings.page == 1 || this.lastSearchResultCount >= 20) {
                const lastEntry = document.querySelector("div.anime-grid-component .grid-card-component:last-of-type") as HTMLDivElement;

                if (lastEntry && !this.loading) {
                    const elementOffset = lastEntry.offsetTop + lastEntry.clientHeight;
                    const windowOffset = window.pageYOffset + window.innerHeight;

                    if (windowOffset > elementOffset) {
                        this.loadNextPage();
                    }
                }
            }
        });
    }

    // Prevent multiple parallel running request.
    private onFilterChange = _.debounce(() => this.submitSearch(), 400);

    private submitSearch() {
        this.searchResults = [];
        this.searchSettings.page = 1;

        this.search();
    }

    private loadNextPage() {
        this.searchSettings.page = this.searchSettings.page +1;
        this.search();
    }

    private async search() {
        const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>;
        const searchDataContext = new SearchDataContext();

        if (await observer.validate()) {
            this.loading = true;

            searchDataContext.animeSearch(this.searchSettings).then(x => {
                if (x.successfully && x.data) {
                    this.searchResults = _.unionBy(this.searchResults.concat(x.data), x => x.id);
                    this.lastSearchResultCount = x.data.length;
                }
            }).finally(() => this.loading = false);
        }
    }
}
